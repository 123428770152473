<template>
  <div class="d-flex flex-column justify-center align-center">
    <icon :size="size" :default-color="defaultColor"/>
    <p class="headline primary--text">
      Nenhum resultado encontrado
    </p>
    <p>
      Que tal criar um item ou alterar os termos da sua busca?
    </p>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 250
    },
    defaultColor: {
      type: String
    }
  },
  components: {
    Icon: () => import('./icons/EmptySearch.vue')
  }
}
</script>
